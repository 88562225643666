import { ValidationController, addValidator, config } from "hotwired-stimulus-validation"
import validator from 'validator'

config({
  focusOnError: true
})
export default class extends ValidationController {
  static targets = ["stepper", "valid", "invalid", "load", "continue", "submit", "alert", "email", "check", "form", "next"]

  initialize() {
    this.index = 0
    this.showCurrentStep()
    this.emailIsTaken = this.emailIsTaken.bind(this);
    this.next = this.next.bind(this)
    this.create = this.create.bind(this)
    this.triggerIsTaken = false
    addValidator('emailIsTaken', this.emailIsTaken)
    const that = this;
    document.addEventListener('keyup', function(event) {
      if (event.key === 'Enter') {
        if (that.index == 0) {
          that.nextTarget.dispatchEvent(new Event('click'))
        } else {
          that.submitTarget.dispatchEvent(new Event('click'))
        }
      }
    })
  }

  next() {
    const that = this
    this.triggerIsTaken = true
    const email = that._form.elements.find(e => e.raw == that.emailTarget)
    that.continueTargets[1].setAttribute('hidden', true)
    that.continueTargets[0].removeAttribute('hidden')
    email.validate().then(function(){
      document.querySelectorAll(".error-message").forEach(el => el.remove());
      that.index++
      that.showCurrentStep()
    }).catch(function(error) {
      that.continueTargets[0].setAttribute('hidden', true)
      that.continueTargets[1].removeAttribute('hidden')
      that.triggerIsTaken = false
      that._displayFormErrors();
    })
  }

  create() {
    const that = this
    this._form.validate().then(function(){
      that.loadTargets[1].setAttribute('hidden', true)
      that.loadTargets[0].removeAttribute('hidden')
      that.formTarget.submit();
    }).catch(function(error) {
      const el = that._form.elementsWithError[0];
      if (el) {
        el.raw.focus();
        that._displayFormErrors();
      }
    })
  }

  showCurrentStep() {
    this.stepperTargets.forEach((element, index) => {
      element.hidden = index !== this.index
    })
  }

  emailIsTaken(element) {
    const that = this
    const { value } = element

    if (!that.triggerIsTaken) {
      return new Promise(async (resolve, reject) => {
        resolve()
      })
    }

    if (validator.isEmail(value)) {
      return new Promise(async (resolve, reject) => {
        const response = await fetch(`/users/checks/?email=${value}`)

        if (response.status == 200) {
          that.alertTarget.setAttribute('hidden', true)
          that.invalidTarget.setAttribute('hidden', true)
          that.validTarget.removeAttribute('hidden')
          resolve()
        } else if (response.status == 422) {
          that.validTarget.setAttribute('hidden', true)
          that.invalidTarget.removeAttribute('hidden')
          that.alertTarget.removeAttribute('hidden')
          reject('')
        } else {
          that.alertTarget.setAttribute('hidden', true)
          that.validTarget.setAttribute('hidden', true)
          that.invalidTarget.removeAttribute('hidden')
          reject("Email is taken")
        }
      })
    } else {
      return new Promise(async (resolve, reject) => {
        that.alertTarget.setAttribute('hidden', true)
        that.invalidTarget.setAttribute('hidden', true)
        that.validTarget.setAttribute('hidden', true)
        reject('Not a valid email')
      })
    }
  }

  googleAuth() {
    document.getElementById('googleAuth').submit()
  }
}
