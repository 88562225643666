import { Controller } from "@hotwired/stimulus"
import anime from 'animejs'

export default class extends Controller {
  initialize() {
    this.content = JSON.parse(this.element.dataset.content)
    this.initOptions = this.initOptions.bind(this)
  }

  connect() {
    // Wrap every letter in a span
    var textWrappers = document.querySelectorAll(".hero-animation .letters");
    textWrappers.forEach((textWrapper, idx) => {
      if (textWrapper.textContent) {
        textWrapper.innerHTML = textWrapper.textContent.replace(
          /([^\x00-\x80]|\w)/g,
          `<span class='el-${idx}-letter'>$&</span>`
        );
      }
    });

    const timeline = anime.timeline({ loop: true })
    this.content.forEach(function(opt,index) {
      timeline.add({
        targets: `.hero-animation .el-${index}-letter`,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 600,
        delay: (el, i) => 34 * (i + 1)
      }).add({
        targets: `.hero-animation .el-${index}`,
        opacity: 0,
        duration: 500,
        easing: "easeOutExpo",
        delay: 500
      })
    })

    // reveal text once anime.js setup
    var textWrapper = document.querySelector(".hero-animation .text-wrapper");
    if (textWrapper) textWrapper.classList.add("opacity-100");
  }

  initOptions() {
    this.connect.map(function(text, index){
      [{
        targets: `.hero-animation .el-${index}-letter`,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 600,
        delay: (el, i) => 34 * (i + 1)
      }, {
        targets: `.hero-animation .el-${index}`,
        opacity: 0,
        duration: 1000,
        easing: "easeOutExpo",
        delay: 1000
      }]
    })
  }
}
