import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    clientId: String, // Your Google Client ID
    endpoint: String, // Your backend endpoint
  };

  connect() {
    this.loadGoogleOneTap();
  }

  loadGoogleOneTap() {
    if (!this.clientIdValue) {
      console.error('Google Client ID is required.');
      return;
    }

    // Load the Google One Tap script
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    // Callback once the script is loaded
    script.onload = () => {
      // Initialize Google One Tap
      google.accounts.id.initialize({
        client_id: this.clientIdValue, // Your Google Client ID
        callback: this.handleCredentialResponse.bind(this), // Callback to handle the token
        ux_mode: 'popup', // Use popup mode explicitly to avoid FedCM
        auto_select: false, // Disable auto-login for better user control
        cancel_on_tap_outside: true, // Allow users to cancel by clicking outside
        context: 'signin', // Set the context to "signin"
      });

      // Trigger the One Tap prompt
      google.accounts.id.prompt(); // Show the prompt
    };

    // Append the script to the document head
    document.head.appendChild(script);
  }

  async handleCredentialResponse(response) {
    console.log('Google Credential Response:', response);

    const idToken = response.credential;

    try {
      const res = await fetch(this.endpointValue, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idToken }),
      });

      const data = await res.json();

      if (res.ok) {
        console.log('User authenticated successfully:', data);
        // Handle successful login
        window.location.reload();
      } else {
        console.error('Authentication failed:', data);
      }
    } catch (error) {
      console.error('Error authenticating user:', error);
    }
  }
}
