import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    hiddenClass: String,
    time: Number,
  }

  initialize() {
    if (this.timeValue) {
      this.timer()
    }
  }

  close() {
    if (this.hiddenClassValue === '') {
      this.element.remove()
    } else {
      this.element.classList.add(this.hiddenClassValue)
    }
  }

  timer() {
    setTimeout(() => { this.close() }, this.timeValue)
  }

  resended(e) {
    if (e.detail.success) {
      const $message = e.target.closest('.message')
      if ($message) {
        $message.innerHTML = "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account."
      }
    }
  }
}
