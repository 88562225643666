import { Controller } from "@hotwired/stimulus"
import { CountUp } from "countup.js"
import Swiper from "swiper"

export default class extends Controller {
  static targets = ["prospectCounter", "userCounter", "tabSlide", "customerSlide", "pagination"]

  connect() {
    const prospectCounter = new CountUp('prospectCounter', this.prospectCounterTarget.dataset.counter);
    if (!prospectCounter.error) {
      prospectCounter.start();
    } else {
      prospectCounter.error(countUp.error);
    }

    const userCounter = new CountUp('userCounter', this.userCounterTarget.dataset.counter);
    if (!userCounter.error) {
      userCounter.start();
    } else {
      console.error(userCounter.error);
    }

    this.swiper = new Swiper(this.tabSlideTarget, {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      // Swiper parameters
      slidesPerView: 1,
      spaceBetween: 10,
      // Remove default pagination
      pagination: {
        el: null,
      },
      on: {
        slideChange: () => this.updatePagination(),
      },
    });

    this.customer = new Swiper(this.customerSlideTarget, {
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      // Swiper parameters
      slidesPerView: 4,
      spaceBetween: 10,
      // Remove default pagination
      pagination: {
        el: null,
      }
    });

    // Update pagination on initial load
    this.updatePagination = this.updatePagination.bind(this);
    this.updatePagination();
  }



  goToSlide(event) {
    const slideIndex = event.target.dataset.slideIndex;
    this.swiper.slideToLoop(slideIndex);
    this.updatePagination(); // Ensure the active class is updated
  }

  updatePagination() {
    const realIndex = this.swiper.realIndex; // Get the real index in the loop
    this.paginationTarget.querySelectorAll('li').forEach((el, index) => {
      el.classList.toggle('active', index == realIndex);
    });
  }
}
