import { Controller } from "@hotwired/stimulus"
import Swiper from "swiper"

export default class extends Controller {
  static targets = ["slider"]

  connect() {
    this.swiper = new Swiper('.animeslide', {
      autoplay: {
        delay: 5000
      },
      // Swiper parameters
      effect: "fade",
      slidesPerView: 1,
      spaceBetween: 10,
      // Remove default pagination
      pagination: {
        el: null,
      }
    });
  }
}
