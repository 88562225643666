import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    duration: Number,
    recentClass: String
  }; // Define the duration as a controller value

  connect() {
    try {
      console.log('Connect recent');
      this.recentClasses = this.recentClassValue || 'animate-pulse bg-green-100/80';
      this.addRecentItem = this.addRecentItem.bind(this);
      // Initial setup if needed
      if (this.recentClasses) {
        this.addRecentItem(this.element);
      }
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  addRecentItem(element) {
    try {
      const that = this;
      // Add the 'recent' class to the item
      element.classList.add(...that.recentClasses.split(' '));

      // Schedule the removal of the 'recent' class after the specified duration
      setTimeout(() => {
        element.classList.remove(...that.recentClasses.split(' '));
      }, that.durationValue || 3000); // Default to 3000ms if duration is not set

    } catch (error) {
      console.error("Error in connect:", error);
    }
  }
}
