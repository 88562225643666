import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    const url = this.element.dataset.goBack
    setTimeout(function() {
      Turbo.visit(url);
    }, 4000)
  }
}
