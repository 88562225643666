import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["next", "prev", "pageSelect"];
  static values = {
    currentPage: Number,
    totalPages: Number
  };

  connect() {
    try {
      this.updateButtons();
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  nextPage() {
    try {
      if (this.currentPageValue < this.totalPagesValue) {
        this.currentPageValue++;
        this.updateButtons();
        this.goToPage();
      }
    } catch (error) {
      console.error("Error in nextPage:", error);
    }
  }

  prevPage() {
    try {
      if (this.currentPageValue > 1) {
        this.currentPageValue--;
        this.updateButtons();
        this.goToPage();
      }
    } catch (error) {
      console.error("Error in prevPage:", error);
    }
  }

  goToPage(event) {
    try {
      if (event) {
        this.currentPageValue = parseInt(event.target.value, 10);
      }
      this.updateButtons();
      this.dispatch("change", { detail: { page: this.currentPageValue } });
    } catch (error) {
      console.error("Error in goToPage:", error);
    }
  }

  updateButtons() {
    try {
      if (this.hasPrevTarget) {
        this.prevTarget.disabled = this.currentPageValue === 1;
      }

      if (this.hasNextTarget) {
        this.nextTarget.disabled = this.currentPageValue === this.totalPagesValue;
      }

      // Update the page select dropdown
      if (this.hasPageSelectTarget) {
        this.pageSelectTarget.value = this.currentPageValue;
      }
    } catch (error) {
      console.error("Error in updateButtons:", error);
    }
  }
}
