// controllers/lazyload_controller.js
import { Controller } from "@hotwired/stimulus";
import LazyLoad from "vanilla-lazyload";

export default class extends Controller {
  static values = {
    options: Object,
    fallbackImage: String // New value for the fallback image URL
  }

  connect() {
    console.log('Lazyload connected')
    this.lazyLoadInstance = new LazyLoad({
      ...this.defaultOptions(),
      ...this.optionsValue
    });

    // Handle automatic update when new elements are added to the DOM
    this.observeMutations();
  }

  disconnect() {
    if (this.lazyLoadInstance) {
      this.lazyLoadInstance.destroy();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  // Default options for lazy loading
  defaultOptions() {
    return {
      elements_selector: ".lazy", // Default selector for lazy-loaded elements
      threshold: 300, // Load images when they are 300px from the viewport
      callback_error: (el) => this.handleImageError(el),
    };
  }

  // Handle image loading error and set a fallback image
  handleImageError(el) {
    if (this.fallbackImageValue) {
      el.src = this.fallbackImageValue;
    }
  }

  // Automatically update lazy load when new elements are added to the DOM
  observeMutations() {
    this.mutationObserver = new MutationObserver((mutations) => {
      this.lazyLoadInstance.update();

      if (mutations) {
        const noData = document.getElementById('no_data');
        if (noData) {
          noData.remove();
        }
      }
    });

    this.mutationObserver.observe(this.element, {
      childList: true,
      subtree: true
    });
  }
}
