import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  signupModal(event) {
    const signupModalButton = document.getElementById('signupModalButton')
    if (signupModalButton) {
      signupModalButton.click()
    }
  }

  upgradeModal(event) {
    const upgradeModalButton = document.getElementById('upgradeModalButton')
    if (upgradeModalButton) {
      upgradeModalButton.click()
    }
  }
}
