import { Controller } from "@hotwired/stimulus";
import { passwordStrength } from "check-password-strength";

// Define Tailwind color classes for each password strength level
const strengthLevels = [
  { level: 0, colorClass: 'bg-red-500', label: 'Very Weak' },
  { level: 1, colorClass: 'bg-orange-500', label: 'Weak' },
  { level: 2, colorClass: 'bg-yellow-500', label: 'Moderate' },
  { level: 3, colorClass: 'bg-green-500', label: 'Strong' }
];

// Password requirements checklist
const passwordRequirements = [
  { regex: /.{8,}/, label: 'At least 8 characters' },
  { regex: /[A-Z]/, label: 'At least one uppercase letter' },
  { regex: /[a-z]/, label: 'At least one lowercase letter' },
  { regex: /[0-9]/, label: 'At least one number' },
  { regex: /[^A-Za-z0-9]/, label: 'At least one special character (!@#$%^&*)' }
];

export default class extends Controller {
  static targets = ['password', 'strengthIndicator', 'step', 'requirement'];

  connect() {
    // Hide the indicator and checklist initially
    this.strengthIndicatorTarget.classList.add('hidden');
  }

  checkStrength() {
    const password = this.passwordTarget.value;

    // Show the indicator and checklist when user starts typing
    if (password.length > 0) {
      this.strengthIndicatorTarget.classList.remove('hidden');
    } else {
      this.strengthIndicatorTarget.classList.add('hidden');
    }

    const result = passwordStrength(password).id;
    const level = strengthLevels[result] || strengthLevels[0];
    // Update the strength indicator text with the label
    this.strengthIndicatorTarget.querySelector('.strength-label').textContent = level.label;

    // Fill the step indicators up to the achieved strength level
    this.stepTargets.forEach((step, index) => {
      if (result == 0) {
        step.classList.remove(...strengthLevels.map(l => l.colorClass));
      }
      if (index <= result) {
        step.classList.add(strengthLevels[index].colorClass);
      } else {
        step.classList.remove(...strengthLevels.map(l => l.colorClass));
      }
    });

    // Update the password requirements checklist
    this.requirementTargets.forEach((requirement, index) => {
      if (passwordRequirements[index].regex.test(password)) {
        requirement.classList.add('text-green-500', 'is-through');
        requirement.classList.remove('text-gray-500');
      } else {
        requirement.classList.add('text-gray-500');
        requirement.classList.remove('text-green-500', 'is-through');
      }
    });
  }
}
