import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["timer", "link"]; // Define timer and link as targets
  static values = { time: Number, url: String }; // Define time and url as values

  connect() {
    this.remainingTime = this.timeValue; // Set initial time
    this.updateTimerDisplay(); // Display the initial timer
    this.startCountdown(); // Start the countdown
  }

  startCountdown() {
    this.interval = setInterval(() => {
      if (this.remainingTime > 0) {
        this.remainingTime -= 1;
        this.updateTimerDisplay();
      } else {
        this.redirectToLink();
      }
    }, 1000); // Update every second
  }

  updateTimerDisplay() {
    if (this.hasTimerTarget) {
      this.timerTarget.textContent = this.remainingTime;
    }
  }

  redirectToLink() {
    clearInterval(this.interval); // Stop the interval
    const url = this.urlValue || this.linkTarget?.href; // Use urlValue or link target href
    if (url) {
      window.location.href = url;
    }
  }
}
