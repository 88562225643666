import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["drawer", "overlay"]

  connect() {
    this.toggle = this.toggle.bind(this)
    this.close = this.close.bind(this)

    this.toggle()
  }

  toggle() {
    this.drawerTarget.classList.toggle("open")
    this.overlayTarget.classList.toggle("open")
    this.drawerTarget.style.left = 0;
  }

  close() {
    this.drawerTarget.classList.remove("open")
    this.overlayTarget.classList.remove("open")
    this.drawerTarget.style.left = "-100%";
  }
}
