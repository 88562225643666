import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="pagy"
export default class extends Controller {
  visit(event) {
    event.preventDefault(); // Prevent default link behavior

    // Get the URL from the link
    const url = event.currentTarget.getAttribute("href");

    // Perform the Turbo Frame visit
    Turbo.visit(url, { frame: this.element.dataset.turboFrame || "downloaded_lists" });
  }

  connect() {
    document.addEventListener("turbo:before-fetch-request", this.startProgress.bind(this))
    document.addEventListener("turbo:before-fetch-response", this.stopProgress.bind(this))
  }

  disconnect() {
    document.removeEventListener("turbo:before-fetch-request", this.startProgress.bind(this))
    document.removeEventListener("turbo:before-fetch-response", this.stopProgress.bind(this))
  }

  startProgress(event) {
    const frameElement = event.target;
    // Alternatively, if you're interested in Turbo Stream elements
    if (frameElement.tagName.toLowerCase() === "turbo-frame" && frameElement.dataset.turboProgressBar) {
      Turbo.navigator.delegate.adapter.showProgressBar();
    }
  }

  stopProgress(event) {
    const frameElement = event.target;
    // Alternatively, if you're interested in Turbo Stream elements
    if (frameElement.tagName.toLowerCase() === "turbo-frame" && frameElement.dataset.turboProgressBar) {
      Turbo.navigator.delegate.adapter.progressBar.hide()
    }
  }
}
