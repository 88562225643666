import { isValid, isExpirationDateValid, isSecurityCodeValid, getCreditCardNameByNumber } from 'creditcard.js'
import { ValidationController, addValidator, config } from "hotwired-stimulus-validation"
import IMask from 'imask'

config({
  focusOnError: true
})

export default class extends ValidationController {
  static targets = ['input', 'name', 'type', 'month', 'year']

  initialize() {
    this.creditCard = this.creditCard.bind(this)
    this.expiryCard = this.expiryCard.bind(this)
    addValidator('creditCard', this.creditCard)
    addValidator('expiryCard', this.expiryCard)
    IMask(this.monthTarget, { mask: '00', max: 12, min: 1 })
    IMask(this.yearTarget, { mask: '0000', min: new Date().getFullYear() })
    IMask(this.inputTarget, { mask: '0000 0000 0000 0000' })
  }


  creditCard(element) {
    const that = this
    const { value } = element

    if (isValid(value)) {
      const name = getCreditCardNameByNumber(value);

      return new Promise(async (resolve, reject) => {
        if (name == 'Credit card is invalid!') {
          reject(name)
        } else {
          that.typeTarget.innerHTML = `${name} Card`;
          that.nameTarget.value = name;
          resolve()
        }
      })
    } else {
      return new Promise(async (resolve, reject) => { reject('Credit card number is in invalid format') })
    }
  }

  expiryCard(element) {
    const month = this.monthTarget.value
    const year = this.yearTarget.value

    if (isExpirationDateValid(month, year)) {
      return new Promise(async (resolve, reject) => { resolve() })
    } else {
      return new Promise(async (resolve, reject) => { reject('Expiration date is in invalid') })
    }
  }

  cancel() {
    const that = this
    that._removeEventListeners()
  }
}