import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['radio', 'button']

  connect() {
    this.toggle = this.toggle.bind(this)
    this.modal = this.modal()
  }

  radioTargetConnected(radio) {
    radio.addEventListener('change', this.toggle.bind(this))
  }

  radioTargetDisconnected(radio) {
    radio.removeEventListener('change', this.toggle.bind(this))
  }

  toggle(event) {
    if (event.target.checked) {
      const $dropdownText = document.querySelector('.dropdownText')
      if ($dropdownText) {
        $dropdownText.innerHTML = event.target.title
      }

      const $transferModalTitle = document.getElementById('transferModalTitle')
      if ($transferModalTitle) {
        $dropdownText.innerHTML = `Transfer to ${event.target.title}`
      }

      this.modal.show()
    }

    if (event.target.checked) {
      this.buttonTarget.classList.remove("opacity-65", "cursor-not-allowed")
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.classList.add("opacity-65", "cursor-not-allowed")
      this.buttonTarget.disabled = true
    }
  }

  modal() {
    // set the modal menu element
    const $targetEl = document.getElementById('transferModal');

    // options with default values
    const options = {
      placement: 'bottom-right',
      backdrop: 'dynamic',
      backdropClasses:
          'bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40',
      closable: true,
      onHide: () => {
      },
      onShow: () => {
      },
      onToggle: () => {
      },
    };

    // instance options object
    const instanceOptions = {
      id: 'transferModal',
      override: true
    };

    return new Modal($targetEl, options, instanceOptions);
  }

  close() {
    this.modal.hide()
  }
}
