import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "mode"];

  initialize() {
    try {
      this.clicked = this.clicked.bind(this);
      this.refresh = this.refresh.bind(this);
      this.updateActiveSearch = this.updateActiveSearch.bind(this);
    } catch (error) {
      console.error("Error in initialize:", error);
    }
  }

  connect() {
    try {
      this.key = this.element.dataset.key;
      this.updateActiveSearch('init');
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  checkboxTargetConnected(checkbox) {
    try {
      checkbox.addEventListener("change", this.clicked);
    } catch (error) {
      console.error("Error in checkboxTargetConnected:", error);
    }
  }

  checkboxTargetDisconnected(checkbox) {
    try {
      checkbox.removeEventListener("change", this.clicked);
    } catch (error) {
      console.error("Error in checkboxTargetDisconnected:", error);
    }
  }

  refresh() {
    try {
      // Update the active search based on the refreshed state
      this.updateActiveSearch('refresh');
    } catch (error) {
      console.error("Error in refresh:", error);
    }
  }

  clicked() {
    try {
      this.updateActiveSearch('click');
    } catch (error) {
      console.error("Error in clicked:", error);
    }
  }

  checked() {
    try {
      return this.checkboxTargets.filter((checkbox) => checkbox.checked);
    } catch (error) {
      console.error("Error in checked:", error);
      return [];
    }
  }

  unchecked() {
    try {
      return this.checkboxTargets.filter((checkbox) => !checkbox.checked);
    } catch (error) {
      console.error("Error in unchecked:", error);
      return [];
    }
  }

  updateActiveSearch(action = 'change') {
    try {
      const accordion = document.getElementById(`accordion-flush-${this.key}`);
      const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`);
      const values = this.checked();

      if (accordion) {
        const badge = accordion.querySelector(`.badge-${this.key}`);
        if (values.length > 0) {
          accordion.classList.add('filled-search');
          accordionBody.classList.add('filled-search');
          badge.innerHTML = values.length;
        } else {
          accordion.classList.remove('filled-search');
          accordionBody.classList.remove('filled-search');
          badge.innerHTML = values.length;
        }
      }

      this.dispatch(action, { detail: { field_name: this.key, field_value: values } });
    } catch (error) {
      console.error("Error in updateActiveSearch:", error);
    }
  }
}
