import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['tab'];
  static values = {
    currentTab: String
  }

  connect() {
    try {
      setTimeout(this.initTab.bind(this), 100)
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  initTab() {
    // Get the current hash without the "#" symbol
    const hash = window.location.hash.slice(1);
    // Set the active tab based on the hash, if available
    if (hash) {
      this.showTab(`#${hash}`);
    } else if (this.currentTabValue) {
      // If there's no hash but we have a default tab, show it
      this.showTab(this.currentTabValue);
    }
  }

  tabTargetConnected(tab) {
    try {
      tab.addEventListener("click", this.selectTab.bind(this));
    } catch (error) {
      console.error("Error in tabTargetConnected:", error);
    }
  }

  tabTargetDisconnect(tab) {
    try {
      tab.addEventListener("click", this.selectTab.bind(this));
    } catch (error) {
      console.error("Error in tabTargetDisconnect:", error);
    }
  }

  selectTab(event) {
    try {
      this.currentTabValue = event.currentTarget.dataset.tabsTarget;

      this.updateHash(this.currentTabValue);
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  // Handle tab clicks
  selectTab(event) {
    event.preventDefault();
    const tabId = event.currentTarget.dataset.tabsTarget; // Get the target tab ID from data attribute

    this.showTab(tabId);
    this.updateHash(tabId);
  }

  // Show the specified tab by ID
  showTab(tabId) {
    document.querySelector(`[data-tabs-target="${tabId}"]`).click()
    const targetLink = this.tabTargets.find(tab => tab.dataset.tabsTarget === tabId);
    if (targetLink) {
      targetLink.click();
    }
  }

  // Update the hash without reloading the page
  updateHash(tabId) {
    if (window.history.pushState) {
      window.history.pushState(null, null, `${tabId}`);
    } else {
      window.location.hash = tabId; // Fallback for older browsers
    }
  }

  resended(e) {
    if (e.detail.success) {
      e.target.replaceWith(
        document.createRange().createContextualFragment(`
          <div class="inline-flex items-center gap-1 bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
            <svg class="text-green-400" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 13.3414C21.3744 13.1203 20.7013 13 20 13C16.6863 13 14 15.6863 14 19C14 19.7013 14.1203 20.3744 14.3414 21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V13.3414ZM12.0606 11.6829L5.64722 6.2377L4.35278 7.7623L12.0731 14.3171L19.6544 7.75616L18.3456 6.24384L12.0606 11.6829ZM19 22L15.4645 18.4645L16.8787 17.0503L19 19.1716L22.5355 15.636L23.9497 17.0503L19 22Z"></path>
            </svg>
            Sent
          </div>
        `)
      );
    }
  }

  requested(e) {
    if (e.detail.success) {
      const modal = e.target.closest('#cancel-subscription-modal');
      if (modal) {
        modal.querySelector('#reason_to_cancel').value = '';
        modal.querySelector('[data-modal-hide]').click();
      }

      const button = document.querySelector('[data-modal-toggle="cancel-subscription-modal"]')
      if (button) {
        button.disabled = true;
      }
    }
  }
}
