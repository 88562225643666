import { ValidationController, config } from "hotwired-stimulus-validation"

config({
  focusOnError: true
})

export default class extends ValidationController {
  static targets = ["load", "form", "button", "email", "password"]

  initialize() {
    this.signIn = this.signIn.bind(this);
  }

  signIn() {
    const that = this
    this._form.validate().then(function(){
      that.loadTargets[1].setAttribute('hidden', true)
      that.loadTargets[0].removeAttribute('hidden')
      that.formTarget.submit();
    }).catch(function(error) {
      const el = that._form.elementsWithError[0];
      if (el) {
        el.raw.focus();
        that._displayFormErrors();
      }
    })
  }
}
