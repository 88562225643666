import ModalController from "./modal_controller"

export default class extends ModalController {
  connect() {
    super.connect()
    const that = this;
    this.initModal = this.initModal.bind(this);
    this.element.addEventListener('click', function(event){
      event.preventDefault();
      that.initModal();
    })
  }

  initModal() {
    const that = this;
    turboFetch(this.element.href || this.element.dataset.url, function(){
      that.modal.show();
    })
  }
}
