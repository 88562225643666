import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["paginations", "pagination"];

  connect() {
    this.paginationsTarget.addEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    const threshold = 100; // Adjust as needed
    if (this.paginationsTarget.scrollTop + this.paginationsTarget.clientHeight >= this.paginationsTarget.scrollHeight - threshold) {
      this.loadMore();
    }
  }

  loadMore() {
    const frame = document.getElementById('pagination');
    if (frame) {
      frame.removeAttribute('complete')
      frame.removeAttribute('loading')
      frame.reload()
    };
  }
}
