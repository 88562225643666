import { Controller } from "@hotwired/stimulus";
import Choices from 'choices.js';

const classNames = {
  containerOuter: ['choices'],
  containerInner: ['choices__inner'],
  input: ['choices__input'],
  inputCloned: ['choices__input--cloned'],
  list: ['choices__list'],
  listItems: ['choices__list--multiple'],
  listSingle: ['choices__list--single'],
  listDropdown: ['choices__list--dropdown'],
  item: ['choices__item'],
  itemSelectable: ['choices__item--selectable'],
  itemDisabled: ['choices__item--disabled'],
  itemChoice: ['choices__item--choice'],
  description: ['choices__description'],
  placeholder: ['choices__placeholder'],
  group: ['choices__group'],
  groupHeading: ['choices__heading'],
  button: ['choices__button'],
  activeState: ['is-active'],
  focusState: ['is-focused'],
  openState: ['is-open'],
  disabledState: ['is-disabled'],
  highlightedState: ['is-highlighted'],
  selectedState: ['is-selected'],
  flippedState: ['is-flipped'],
  loadingState: ['is-loading'],
  notice: ['choices__notice'],
  addChoice: ['choices__item--selectable', 'add-choice'],
  noResults: ['has-no-results'],
  noChoices: ['has-no-choices'],
};

export default class extends Controller {
  static targets = ['select', 'options'];

  initialize() {
    this.element['choices'] = this;
    this.bindMethods();
    this.searchPath = this.element.dataset.searchPath;
    this.forceOption = this.element.dataset.forceOption || true;
  }

  connect() {
    try {
      setTimeout(() => this.setup(), 5);
    } catch (error) {
      console.error("Error during setup:", error);
    }
  }

  disconnect() {
    try {
      if (this.searchPath) {
        this.input.removeEventListener('input', this.search);
        this.selectTarget.removeEventListener('change', this.refresh);
        this.selectTarget.removeEventListener('addItem', this.add);
        this.selectTarget.removeEventListener('removeItem', this.remove);
      } else {
        this.selectTarget.removeEventListener('change', this.callback);
      }
      this.choices?.destroy();
      this.choices = undefined;
    } catch (error) {
      console.error("Error during disconnect:", error);
    }
  }

  setup() {
    try {
      const choicesOptions = {
        ...this.options(),
        addItemFilter: (value) => {
          this.addItemOnCustomKeyPress(value);
          return true;
        }
      };
      this.choices = new Choices(this.selectTarget, choicesOptions);
      this.input = this.element.querySelector('input');
      this.refresh();

      if (this.searchPath) {
        this.input.addEventListener('input', this.search);
        this.selectTarget.addEventListener('change', this.refresh);
        this.selectTarget.addEventListener('addItem', this.add);
        this.selectTarget.addEventListener('removeItem', this.remove);
      } else {
        this.selectTarget.addEventListener('change', this.callback.bind(this));
        this.choices.input.element.addEventListener("keydown", (e) => {
          if (e.key === "Enter") {
            this.setChoicesValue(this.choices.input.value);
          }
        });
      }
      // Call callback if select has a pre-selected value
      this.callCallbackIfValueExists();
    } catch (error) {
      console.error("Error during setup:", error);
    }
  }

  refresh() {
    try {
      this.choices.setChoices([], 'value', 'label', true);
      if (this.hasOptionsTarget) {
        Array.from(this.optionsTarget.children).forEach(this.append.bind(this));
      }
    } catch (error) {
      console.error("Error during refresh:", error);
    }
  }

  callCallbackIfValueExists() {
    // Check if the select element has a selected value
    const selectedOption = this.selectTarget.querySelector('option[selected]');
    const selectedValue = this.selectTarget.value;

    if (selectedOption || selectedValue) {
      console.log("Pre-selected value found:", selectedValue); // Debugging log

      // Trigger callback with the selected value
      this.callback({ target: this.selectTarget });
    }
  }

  callback(e) {
    try {
      const selectedValue = e.target.value;
      // Update the URL with the new list_id value
      this.updateURLParam('list_id', selectedValue);
      const elements = document.getElementsByClassName(`${e.target.name}-value`);
      Array.from(elements).forEach(element => {
        element.value = selectedValue;
      });
    } catch (error) {
      console.error("Error in callback:", error);
    }
  }

  updateURLParam(key, value) {
    const url = new URL(window.location.href); // Get current URL
    const params = url.searchParams;

    // Update or set the key-value pair
    params.set(key, value);

    // Use pushState or replaceState to update the URL without reloading
    history.replaceState({}, '', `${url.pathname}?${params.toString()}`);
  }

  append(option) {
    try {
      const existingOption = Array.from(this.selectTarget.options).some(o => o.label === option.label);
      if (!existingOption) {
        this.choices.setChoices([option], 'value', 'label', false);
      }
    } catch (error) {
      console.error("Error during append:", error);
    }
  }

  add(event) {
    try {
      if (this.hasOptionsTarget) {
        const option = Array.from(this.optionsTarget.children).find(option => option.label === event.detail.label);
        if (option) {
          option.setAttribute('selected', '');
        } else {
          const newOption = document.createElement('option');
          newOption.setAttribute('label', event.detail.label);
          newOption.setAttribute('value', event.detail.value);
          newOption.setAttribute('selected', '');
          this.optionsTarget.appendChild(newOption);
        }
      }
    } catch (error) {
      console.error("Error during add:", error);
    }
  }

  remove(event) {
    try {
      if (this.hasOptionsTarget) {
        const option = Array.from(this.optionsTarget.children).find(item => item.label === event.detail.label);
        if (option) {
          this.searchPath ? option.remove() : option.removeAttribute('selected');
        }
      }
      if (this.forceOption && !this.selectTarget.options.length) {
        this.selectTarget.add(document.createElement('option'));
      }
    } catch (error) {
      console.error("Error during remove:", error);
    }
  }

  search(event) {
    try {
      if (event.target.value) {
        fetch(this.searchPath + event.target.value, {
          headers: { 'X-Requested-With': 'XMLHttpRequest' }
        })
          .then(response => response.json())
          .then(this.update)
          .catch(error => console.error("Error fetching search results:", error));
      } else {
        this.refresh();
      }
    } catch (error) {
      console.error("Error during search:", error);
    }
  }

  update(data) {
    try {
      this.choices.setChoices(data.filter(this.filter), 'value', 'label', true);
    } catch (error) {
      console.error("Error during update:", error);
    }
  }

  filter(item) {
    try {
      return !Array.from(this.selectTarget.options).some(option => option.label === item.label);
    } catch (error) {
      console.error("Error during filter:", error);
      return false;
    }
  }

  options() {
    return 'silent renderChoiceLimit maxItemCount addItems removeItems removeItemButton editItems duplicateItemsAllowed delimiter paste searchEnabled searchChoices searchFloor searchResultLimit position resetScrollPosition addItemFilter shouldSort shouldSortItems placeholder placeholderValue prependValue appendValue renderSelectedChoices loadingText noResultsText noChoicesText itemSelectText addItemText maxItemText'
      .split(' ')
      .reduce(this.optionsReducer, { classNames: classNames });
  }

  optionsReducer(accumulator, currentValue) {
    try {
      if (currentValue in this.element.dataset) {
        let value = this.element.dataset[currentValue];
        accumulator[currentValue] = ['true', 'false'].includes(value) ? JSON.parse(value) : value;
      }
    } catch (error) {
      console.error(`Error in optionsReducer for ${currentValue}:`, error);
    }
    return accumulator;
  }

  setChoicesValue(value) {
    try {
      if (value) {
        this.choices.setValue([value]);
        this.choices.clearInput();
        this.choices.hideDropdown(true);
      }
    } catch (error) {
      console.error("Error in setChoicesValue:", error);
    }
  }

  addItemOnCustomKeyPress(value) {
    try {
      const addItemCharacters = ',; ';
      if (this.choices && value && addItemCharacters.includes(value.slice(-1))) {
        this.setChoicesValue(value.slice(0, -1));
      }
    } catch (error) {
      console.error("Error in addItemOnCustomKeyPress:", error);
    }
  }

  bindMethods() {
    const methods = [
      "refresh", "add", "remove", "search", "update",
      "filter", "options", "setChoicesValue", "addItemOnCustomKeyPress",
      "optionsReducer", "callback", "append"
    ];
    methods.forEach(method => {
      try {
        if (typeof this[method] === 'function') {
          this[method] = this[method].bind(this);
        } else {
          console.warn(`Method "${method}" is not defined or not a function.`);
        }
      } catch (error) {
        console.error(`Error binding method ${method}:`, error);
      }
    });
  }
}