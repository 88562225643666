import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['radio', 'min', 'max'];

  connect() {
    try {
      this.selectInputMode = this.selectInputMode.bind(this);
      this.updateActiveSearch = this.updateActiveSearch.bind(this);
      this.firstCheckedValue = this.firstCheckedValue.bind(this);
      this.toggleAccordion = this.toggleAccordion.bind(this);
      this.toggleInputAccordion = this.toggleInputAccordion.bind(this);
      this.checkForValues = this.checkForValues.bind(this);
      this.refresh = this.refresh.bind(this);

      this.key = this.element.dataset.key;
      this.mode = this.firstCheckedValue() || 'predefined';
      this.updateActiveSearch(true);
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  firstCheckedValue() {
    try {
      return this.radioTargets.filter(radio => radio.checked).map(radio => radio.value)[0];
    } catch (error) {
      console.error("Error in firstCheckedValue:", error);
      return null;
    }
  }

  toggleInputAccordion() {
    try {
      this.radioTargets.forEach(radio => {
        const container = document.getElementById(`accordion-size-${radio.value}`);
        const disabled = radio.value !== this.mode;

        if (container) {
          const elements = container.querySelectorAll(
            'input[type="text"], input[type="number"], select, input[type="checkbox"], input[type="radio"]'
          );
          elements.forEach(element => { element.disabled = disabled; });
        }
      });
    } catch (error) {
      console.error("Error in toggleInputAccordion:", error);
    }
  }

  radioTargetConnected(radio) {
    try {
      radio.addEventListener("change", this.selectInputMode);
    } catch (error) {
      console.error("Error in radioTargetConnected:", error);
    }
  }

  minTargetConnected(min) {
    try {
      min.addEventListener("change", this.refresh);
    } catch (error) {
      console.error("Error in minTargetConnected:", error);
    }
  }

  maxTargetConnected(max) {
    try {
      max.addEventListener("change", this.refresh);
    } catch (error) {
      console.error("Error in maxTargetConnected:", error);
    }
  }

  refresh() {
    try {
      this.updateActiveSearch();
    } catch (error) {
      console.error("Error in refresh:", error);
    }
  }

  selectInputMode(event) {
    try {
      if (event.currentTarget.checked) {
        this.mode = event.currentTarget.value;
        this.updateActiveSearch();
      }
    } catch (error) {
      console.error("Error in selectInputMode:", error);
    }
  }

  toggleAccordion(event) {
    try {
      const checkbox = event.currentTarget.querySelector(`#${event.currentTarget.getAttribute('for')}`);
      if (checkbox) {
        checkbox.checked = true;
        this.mode = checkbox.value;
        this.toggleInputAccordion();
        this.updateActiveSearch();
      }
    } catch (error) {
      console.error("Error in toggleAccordion:", error);
    }
  }

  updateActiveSearch(init = false) {
    try {
      console.log('size_range', init, 'updateActiveSearch');

      const accordion = document.getElementById(`accordion-flush-${this.key}`);
      const accordionBody = document.getElementById(`accordion-flush-body-${this.key}`);
      let values = [];

      if (accordion) {
        const badge = accordion.querySelector(`.badge-${this.key}`);

        switch (this.mode) {
          case 'unknow':
            values = [this.mode];
            accordion.classList.add('filled-search');
            accordionBody.classList.add('filled-search');
            badge.innerHTML = values.length;
            break;
          default:
            if (this.checkForValues(this.mode)) {
              values = [this.mode];
            }

            if (values.length > 0) {
              accordion.classList.add('filled-search');
              accordionBody.classList.add('filled-search');
              badge.innerHTML = values.length;
            } else {
              accordion.classList.remove('filled-search');
              accordionBody.classList.remove('filled-search');
              badge.innerHTML = values.length;
            }
            break;
        }

        const action = init ? "init" : "change";
        this.dispatch(action, { detail: { field_name: this.key, field_value: values } });
      }
    } catch (error) {
      console.error("Error in updateActiveSearch:", error);
    }
  }

  checkForValues(mode) {
    try {
      const container = document.getElementById(`accordion-size-${mode || this.mode}`);

      if (container) {
        const elements = container.querySelectorAll(
          'input[type="text"], input[type="number"], select, input[type="checkbox"], input[type="radio"]'
        );

        // Determine if any element has a non-empty value or is checked
        return Array.from(elements).some(element => {
          if (['text', 'number', 'email'].includes(element.type) || element.tagName.toLowerCase() === "select") {
            return element.value.trim() !== '';
          }
          if (element.type === "checkbox" || element.type === "radio") {
            return element.checked;
          }
          return false;
        });
      } else {
        return false;
      }
    } catch (error) {
      console.error("Error in checkForValues:", error);
      return false;
    }
  }
}
