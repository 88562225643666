import CheckboxSelectAll from '@stimulus-components/checkbox-select-all'

export default class extends CheckboxSelectAll {
  static targets = ["checkbox", "checkboxAll"]

  connect() {
    super.connect()
    this.updateSelectIds = this.updateSelectIds.bind(this)
    this.toggleDeleteButton = this.toggleDeleteButton.bind(this)
    this.toggleDeleteButton()
  }

  toggleDeleteButton() {
    this.updateSelectIds()
  }

  toggle(e) {
    super.toggle(e)
    this.updateSelectIds()
  }

  updateSelectIds() {
    const deleteButton = document.getElementById('deleteButton')
    const form = document.getElementById('deleteButton').closest('form')
    if (this.checked.length > 0) {
      const ids = this.checked.map((c) => c.value).join(',')
      form.setAttribute('action', `/lists/${ids}?format=js`)
      deleteButton.classList.remove("d-none")
    } else {
      form.setAttribute('action', '/lists/0?format=js')
      deleteButton.classList.add("d-none")
    }
  }
}
