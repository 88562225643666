import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    try {
      this.select = this.select.bind(this);
      this.save = this.save.bind(this);
      this.submitEnd = this.submitEnd.bind(this);
      this.switchMode = this.switchMode.bind(this);

      this.element.addEventListener("submit", this.save);
    } catch (error) {
      console.error("Error in connect:", error);
    }
  }

  select(event) {
    try {
      const element = event.currentTarget;
      const url = element.dataset.changeUrl.replace(/__ID__/, element.value);

      window.fetch(url, {
        headers: { Accept: "text/vnd.turbo-stream.html" }
      })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
          return true;
        })
        .catch(error => console.error("Error in fetch:", error));
    } catch (error) {
      console.error("Error in select:", error);
    }
  }

  save(event) {
    try {
      event.preventDefault();

      const searchForm = document.getElementById('searchForm');
      const saveSearchForm = document.getElementById('saveSearchForm');
      const action = saveSearchForm?.getAttribute('action');
      const method = saveSearchForm?.getAttribute('method') || 'post';
      const modal = event.target.closest('#save-search-modal');
      const csrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

      if (!action || !csrfToken) {
        console.error("Missing form action or CSRF token");
        return;
      }

      // Create a new FormData object and append necessary parameters
      let params = new FormData(saveSearchForm);
      for (let [key, value] of new FormData(searchForm).entries()) {
        if (key !== 'search_terms') {
          params.append(key, value);
        }
      }
      params.append('submit', 'save');

      fetch(action, {
        method: method,
        headers: {
          Accept: "text/vnd.turbo-stream.html",
          'X-CSRF-Token': csrfToken
        },
        body: params
      })
        .then(r => r.text())
        .then(html => {
          Turbo.renderStreamMessage(html);
          this.clearForm();

          if (modal) {
            const closeButton = modal.querySelector('button[data-modal-hide="save-search-modal"]');
            if (closeButton) closeButton.click();
          }
          return true;
        })
        .catch(error => console.error("Error in save fetch:", error));
    } catch (error) {
      console.error("Error in save:", error);
    }
  }

  switchMode(event) {
    try {
      const state = event.currentTarget.value === 'new';
      const queryNameInput = document.getElementById('query_name');

      if (queryNameInput) {
        queryNameInput.setAttribute('required', state);
        queryNameInput.setAttribute('disabled', !state);
      }
    } catch (error) {
      console.error("Error in switchMode:", error);
    }
  }

  submitEnd(event) {
    try {
      const response = event.detail.fetchResponse.response;

      if (response.status === 200) {
        this.clearForm();
        const closeButton = this.element.querySelector('button[data-modal-hide="save-search-modal"]');
        if (closeButton) closeButton.click();
      }
    } catch (error) {
      console.error("Error in submitEnd:", error);
    }
  }

  toggleSubmitButton(button) {
    try {
      const originalContent = button.innerHTML;
      const loadingContent = button.getAttribute('data-turbo-submits-with');
      button.setAttribute('data-turbo-submits-with', originalContent);

      // Set the button content to the loading state
      button.innerHTML = loadingContent;
      button.disabled = !button.disabled; // Optionally disable the button
    } catch (error) {
      console.error("Error in toggleSubmitButton:", error);
    }
  }

  clearForm() {
    try {
      const elements = this.element.querySelectorAll('select, input');
      elements.forEach(element => {
        if (element.type === 'checkbox' || element.type === 'radio') {
          element.checked = false; // Uncheck checkboxes and radio buttons
        } else {
          element.value = ''; // Clear the value for other input types and select elements
        }
      });
    } catch (error) {
      console.error("Error in clearForm:", error);
    }
  }
}
