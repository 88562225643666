import { Controller } from "@hotwired/stimulus"
import ScrollTrigger from '@terwanerik/scrolltrigger'
import Sticky from 'sticky-js';

export default class extends Controller {
  connect() {
    const that = this;
    this.navHighlighter = this.navHighlighter.bind(this);
    var sticky = new Sticky('.default-sidebar');
    sticky.update();

    const trigger = new ScrollTrigger({
      scroll: {
        callback: function() {
          that.navHighlighter();
        }
      }
    })

    trigger.createTrigger(this.element, {})
  }

  navHighlighter() {
    const sections = document.querySelectorAll('[data-document-target]');
    // Get current scroll position
    let scrollY = window.scrollY || document.documentElement.scrollTop

    // Now we loop through sections to get height, top and ID values for each
    sections.forEach(current => {
      const sectionHeight = current.offsetHeight;
      const sectionTop = current.offsetTop - 50;
      let sectionId = current.getAttribute("id");
      /*
      - If our current scroll position enters the space where current section on screen is, add .active class to corresponding navigation link, else remove it
      - To know which link needs an active class, we use sectionId variable we are getting while looping through sections as an selector
      */
      if (scrollY > sectionTop && scrollY <= sectionTop + sectionHeight) {
        let link = document.querySelector(`aside a[href='#${sectionId}']`)
        if (link) {
          const location = window.location.toString().split('#')[0];
          history.replaceState(null, null, location + '#' + sectionId);
          link.classList.add("bg-gray-100", "dark:bg-gray-700", "text-semibold", "text-primary-600");
        }
      } else {
        let link = document.querySelector(`aside a[href='#${sectionId}']`)
        if (link) { link.classList.remove("bg-gray-100", "dark:bg-gray-700", "text-semibold", "text-primary-600"); }
      }
    })
  }
}
