import { Controller } from "@hotwired/stimulus"
import parsePhoneNumber from 'libphonenumber-js'

export default class extends Controller {
  static targets = ['phone']

  connect() {
    console.log('Phone format connected')
  }

  phoneTargetConnected(phone) {
    const value = phone.textContent
    const phoneNumber = parsePhoneNumber(value)
    if (phoneNumber) {
      phone.textContent = phoneNumber.formatInternational()
    }
  }
}
