// Import and register all your controllers from the importmap under controllers/*

import { application } from "../controllers/application"
import { registerControllers } from 'stimulus-vite-helpers'

// Eager load all controllers defined in the import map under controllers/**/*_controller
// import { eagerLoadControllersFrom } from "@hotwired/stimulus-loading"
// eagerLoadControllersFrom("channels", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

const channels = import.meta.glob('./**/*_channel.js', { eager: true })
registerControllers(application, channels)
